import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from "firebase/auth"; // Importa getAuth e onAuthStateChanged
import Login from './login/Login';
import Signup from './signup/Cad';
import Main from './main/main';
import Relatorio from './historico/relatorio';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const [loading, setLoading] = useState(true); // Estado para controlar o carregamento
  const [isAuthenticated, setIsAuthenticated] = useState(false); // Estado para controlar a autenticação
  const auth = getAuth(); // Inicializa o auth do Firebase

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsAuthenticated(true); // Usuário está autenticado
      } else {
        setIsAuthenticated(false); // Usuário não está autenticado
      }
      setLoading(false); // Termina o carregamento
    });

    // Limpa o listener ao desmontar o componente
    return () => unsubscribe();
  }, [auth]);

  // Enquanto carrega, pode mostrar um carregando ou nada
  if (loading) {
    return <div>Carregando...</div>; // Você pode substituir por um spinner ou algo mais adequado
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/historico" element={<Relatorio />} />
        <Route
          path="/inicio"
          element={isAuthenticated ? <Main /> : <Navigate to="/" />} // Redireciona para Login se não estiver autenticado
        />
      </Routes>
    </Router>
  );
}

export default App;
