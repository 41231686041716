// EntregasList.js
import React, { useEffect, useState } from 'react';
import { getDatabase, ref, onValue, update, get } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import Lottie from 'lottie-react'; // Importa o Lottie
import emptyAnimation from '../../assets/empty.json'; // Importa o arquivo da animação
import Modal from 'react-modal'; // Biblioteca para modal
import AddressComponent from '../editAddressComponent/editAddressComponent';

// Configuração do modal (pode ser alterado conforme necessário)
Modal.setAppElement('#root');

const EntregasList = () => {
  const [entregas, setEntregas] = useState({});
  const [loading, setLoading] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false); // Estado para controlar se o modal está aberto
  const [selectedEntrega, setSelectedEntrega] = useState(null); // Estado para armazenar a entrega selecionada
  const [selectedEntregaId, setSelectedEntregaId] = useState(null); // Estado para armazenar o ID da entrega selecionada
  const [cancelling, setCancelling] = useState(false); // Estado para controlar o processo de cancelamento
  const [error, setError] = useState(null); // Estado para armazenar erros
  const [editModalIsOpen, setEditModalIsOpen] = useState(false); // Estado para o modal de edição

  const auth = getAuth();
  const user = auth.currentUser;

  useEffect(() => {
    if (user) {
      const uid = user.uid; // Pega o UID do usuário logado
      const db = getDatabase();
      const entregasRef = ref(db, `dtmovimento/${uid}`); // Referência no Firebase

      // Busca os dados do Firebase Realtime Database
      onValue(entregasRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          setEntregas(data);
        } else {
          setEntregas({});
        }
        setLoading(false);
      });
    }
  }, [user]);

  const confirmCancel = () => {
    const confirmed = window.confirm('Você tem certeza que deseja cancelar esta corrida?');
    if (confirmed) {
      handleCancel();
    }
  };

  // Função para retornar a cor do card com base no status
  const getStatusColor = (status) => {
    switch (status) {
      case 'Pendente':
        return '#FF0000'; // vermelho
      case 'A caminho':
        return '#FF9900'; // laranja
      case 'Coletado':
        return '#3366FF'; // azul
      case 'Entregue':
        return '#25CE5F'; // verde
      case 'Cancelada':
        return '#7c7c7c'; // cinza
      default:
        return '#ccc'; // cinza
    }
  };

  // Função para abrir o modal com os detalhes da entrega
  const openModal = (entrega, entregaId) => {
    setSelectedEntrega(entrega); // Define a entrega selecionada
    setSelectedEntregaId(entregaId); // Define o ID da entrega selecionada
    setModalIsOpen(true); // Abre o modal
    setError(null); // Reseta erros
  };

  // Função para fechar o modal
  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedEntrega(null); // Limpa a entrega selecionada
    setSelectedEntregaId(null); // Limpa o ID da entrega selecionada
    setError(null); // Reseta erros
  };

  // Função para abrir o modal de edição do endereço
  const openEditModal = (entregaId) => {
    setSelectedEntregaId(entregaId); // Define o ID da entrega selecionada
    setModalIsOpen(false); // Fecha o modal atual de detalhes
    setEditModalIsOpen(true); // Abre o modal de edição
  };

  // Função para fechar o modal de edição
  const closeEditModal = () => {
    setEditModalIsOpen(false);
    setSelectedEntregaId(null);
  };

  // Função para lidar com o cancelamento da corrida
  const handleCancel = async () => {
    if (!selectedEntrega || cancelling || !selectedEntregaId) return;

    setCancelling(true);
    setError(null);

    try {
      const db = getDatabase();
      const uid = user.uid;
      const entregaId = selectedEntregaId; // Usa o ID correto da entrega

      // Referências no Firebase
      const entregaRef = ref(db, `dtmovimento/${uid}/${entregaId}/Status`);
      const valorRef = ref(db, `dtmovimento/${uid}/${entregaId}/Valor`);
      const saldoRef = ref(db, `Empresas/${uid}/Saldo`);

      // Recuperar o valor da corrida
      const valor = parseFloat(selectedEntrega.Valor);
      if (isNaN(valor)) {
        throw new Error('Valor da corrida inválido.');
      }

      // Recuperar o saldo atual
      const saldoSnapshot = await get(saldoRef);
      if (!saldoSnapshot.exists()) {
        throw new Error('Saldo do usuário não encontrado.');
      }
      const saldoAtual = parseFloat(saldoSnapshot.val());
      if (isNaN(saldoAtual)) {
        throw new Error('Saldo atual inválido.');
      }

      // Calcular o novo saldo
      const novoSaldo = saldoAtual + valor;

      // Atualizar o status da entrega para "Cancelada", zerar o valor e atualizar o saldo do usuário
      const updates = {
        [`dtmovimento/${uid}/${entregaId}/Status`]: 'Cancelada',
        [`dtmovimento/${uid}/${entregaId}/Valor`]: '0.00', // Zerar o valor da entrega
        [`Empresas/${uid}/Saldo`]: novoSaldo.toFixed(2), // Formatação para duas casas decimais
      };

      await update(ref(db), updates);

      // Atualizar o estado local
      setEntregas((prevEntregas) => ({
        ...prevEntregas,
        [entregaId]: {
          ...prevEntregas[entregaId],
          Status: 'Cancelada',
          Valor: '0.00', // Zerar o valor na UI também
        },
      }));

      // Fechar o modal
      closeModal();
    } catch (err) {
      console.error(err);
      setError('Erro ao cancelar a corrida. Por favor, tente novamente.');
    } finally {
      setCancelling(false);
    }
  };

  if (loading) {
    return <p>Carregando...</p>;
  }

  // Função para ordenar as entregas, colocando as entregas entregues por último
  const sortedEntregas = Object.keys(entregas).sort((a, b) => {
    const statusA = entregas[a]?.Status ? entregas[a].Status.replace(/['"]+/g, '') : '';
    const statusB = entregas[b]?.Status ? entregas[b].Status.replace(/['"]+/g, '') : '';
    if (statusA === 'Entregue' && statusB !== 'Entregue') return 1;
    if (statusA !== 'Entregue' && statusB === 'Entregue') return -1;
    return 0; // Mantenha a ordem se ambos forem iguais
  });

  return (
    <div>
      <div style={styles.listaContainer}>
        {sortedEntregas.length === 0 ? (
          <div style={styles.emptyContainer}>
            <Lottie animationData={emptyAnimation} style={styles.lottie} loop={false} />
          </div>
        ) : (
          sortedEntregas.map((entregaId) => {
            const entrega = entregas[entregaId];
            const status = entrega?.Status ? entrega.Status.replace(/['"]+/g, '') : 'Desconhecido';
            const motoboy = entrega?.Motoboy ? entrega.Motoboy.replace(/['"]+/g, '') : 'Desconhecido';
            const valor = entrega?.Valor ? parseFloat(entrega.Valor).toFixed(2) : '0.00';

            return (
              <div
                key={entregaId}
                style={styles.entregaCard}
                onClick={() => openModal(entrega, entregaId)} // Passa o ID correto
              >
                <div style={styles.infoContainer}>
                  <h3 style={styles.motoboy}>{motoboy}</h3>
                  <p style={styles.endereco}>
                    {entrega?.Destino ? (
                      JSON.parse(entrega.Destino).map((destino, index) => (
                        <span key={index}>
                          {destino}
                          <br />
                        </span>
                      ))
                    ) : (
                      <span>Destino não disponível</span>
                    )}
                  </p>
                </div>
                <div style={styles.cardsContainer}>
                  <div style={styles.cardItem}>
                    <p style={styles.cardText}>{entrega?.Codigo.replace(/['"]+/g, '') || 'N/A'}</p>
                  </div>
                  <div
                    style={{
                      ...styles.cardItem,
                      backgroundColor: getStatusColor(status),
                    }}
                  >
                    <p style={styles.cardText2}>{status}</p>
                  </div>
                  <div style={styles.cardItem}>
                    <p style={styles.cardText}>R$ {valor}</p>
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>

      {/* Modal de Detalhes da Corrida */}
      {selectedEntrega && (
        <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={modalStyles}>
          <h2>Detalhes da Corrida</h2>
          <p>
            <strong>Entregador:</strong> {selectedEntrega.Motoboy ? selectedEntrega.Motoboy.replace(/['"]+/g, '') : 'Desconhecido'}
          </p>
          <p>
            <strong>Status:</strong> {selectedEntrega.Status ? selectedEntrega.Status.replace(/['"]+/g, '') : 'Desconhecido'}
          </p>
          <p>
            <strong>Telefone:</strong> {selectedEntrega.Telefone ? selectedEntrega.Telefone.replace(/['"]+/g, '') : 'Desconhecido'}
          </p>
          <p>
            <strong>Valor:</strong> R$ {selectedEntrega.Valor ? parseFloat(selectedEntrega.Valor).toFixed(2) : '0.00'}
          </p>
          <p>
            <strong>Data:</strong> {selectedEntrega.Data.replace(/['"]+/g, '') || 'Desconhecida'}
          </p>
          <p>
            <strong>Código:</strong> {selectedEntrega.Codigo ? selectedEntrega.Codigo.replace(/['"]+/g, '') : 'Desconhecida'}
          </p>

          {/* Exibindo Observações em Tabela */}
          {selectedEntrega.Obs ? (
            (() => {
              let obsArray = [];
              try {
                obsArray = JSON.parse(selectedEntrega.Obs);
              } catch (e) {
                console.error('Erro ao parsear Observações:', e);
              }
              return Array.isArray(obsArray) && obsArray.length > 0 ? (
                <table style={styles.table}>
                  <thead>
                    <tr>
                      <th style={styles.tableHeader}>Endereço</th>
                      <th style={styles.tableHeader}>Valor</th>
                      <th style={styles.tableHeader}>Pagamento</th>
                    </tr>
                  </thead>
                  <tbody>
                    {obsArray.map((obsItem, index) => (
                      <tr key={index}>
                        <td style={styles.tableCell}>{obsItem[0]}</td>
                        <td style={styles.tableCell}>
                          R$ {obsItem[1] ? parseFloat(obsItem[1]).toFixed(2) : '0.00'}
                        </td>
                        <td style={styles.tableCell}>{obsItem[3] || 'N/A'}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p>Sem observações.</p>
              );
            })()
          ) : (
            <p>Sem observações.</p>
          )}

          {/* Exibindo Erro, se houver */}
          {error && <p style={styles.errorText}>{error}</p>}

          <div style={styles.modalButtons}>
            {/* Mostrar botão de cancelar apenas se o status não for "Cancelada" ou "Entregue" */}
            {selectedEntrega.Status &&
              selectedEntrega.Status.replace(/['"]+/g, '') !== 'Cancelada' &&
              selectedEntrega.Status.replace(/['"]+/g, '') !== 'Entregue' && (
                <button onClick={confirmCancel} style={styles.cancelButton} disabled={cancelling}>
                  {cancelling ? 'Cancelando...' : 'Cancelar Corrida'}
                </button>
              )}
            {/* Botão de Editar Corrida */}
            {selectedEntrega.Status &&
              selectedEntrega.Status.replace(/['"]+/g, '') !== 'Cancelada' &&
              selectedEntrega.Status.replace(/['"]+/g, '') !== 'Entregue' && (
                <button onClick={() => openEditModal(selectedEntregaId)} style={styles.editButton} disabled={cancelling}>
                  Editar Corrida
                </button>
              )}
            <button onClick={closeModal} style={styles.closeButton}>
              Fechar
            </button>
          </div>
        </Modal>
      )}

      {/* Modal de Edição */}
      {selectedEntregaId && (
        <Modal isOpen={editModalIsOpen} onRequestClose={closeEditModal} style={modalStyles}>
          <h2>Editar Corrida</h2>
          <AddressComponent id_corrida={selectedEntregaId} />
          <button onClick={closeEditModal} style={styles.closeEditModalButton}>
            Fechar
          </button>
        </Modal>
      )}
    </div>
  );
};

// Estilos para o modal
const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '20px',
    borderRadius: '10px',
    width: '80%',
    maxWidth: '600px',
    maxHeight: '80vh',
    overflowY: 'auto',
  },
};

// Estilos originais e adicionais
const styles = {
  listaContainer: {
    maxHeight: '490px', // Define a altura máxima da lista
    overflowY: 'auto', // Ativa a rolagem vertical
    marginBottom: '10px',
  },
  entregaCard: {
    border: '1px solid #ddd',
    padding: '10px',
    margin: '8px 0',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'space-between', // Garante que os itens se espalhem para ocupar todo o espaço
    alignItems: 'center', // Alinha os itens no centro verticalmente
    cursor: 'pointer', // Adiciona cursor de ponteiro
  },
  infoContainer: {
    flex: 1,
    marginRight: '10px', // Espaço entre as informações e os cards
  },
  motoboy: {
    fontSize: '18px',
    marginBottom: '5px',
  },
  endereco: {
    fontSize: '12px',
    marginBottom: '8px',
    lineHeight: '1.4',
  },
  cardsContainer: {
    display: 'flex',
    justifyContent: 'space-between', // Espaço igual entre os cards
    alignItems: 'center', // Alinha os cards verticalmente
    minWidth: '200px', // Largura mínima para os cards
  },
  cardItem: {
    backgroundColor: '#f5f5f5',
    padding: '10px', // Aumente o padding lateral aqui
    margin: '0 5px', // Margem lateral para separar os cards
    borderRadius: '30px',
    textAlign: 'center',
    height: '10px', // Define uma altura menor para o card
    display: 'flex',
    alignItems: 'center', // Centraliza o conteúdo verticalmente
    justifyContent: 'center', // Centraliza o conteúdo horizontalmente
  },
  cardText: {
    fontSize: '14px',
    fontWeight: 'bold',
  },
  cardText2: {
    color: 'white',
    fontSize: '14px',
    fontWeight: 'bold',
  },
  emptyContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%', // Certifica-se que a animação e o texto estejam centralizados
  },
  lottie: {
    marginTop: '100px',
    width: '200px', 
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    marginTop: '10px',
    marginBottom: '20px',
  },
  tableHeader: {
    backgroundColor: '#f2f2f2',
    border: '1px solid #ddd',
    padding: '8px',
    textAlign: 'left',
  },
  tableCell: {
    border: '1px solid #ddd',
    padding: '8px',
    textAlign: 'left',
  },
  modalButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '20px',
  },
  cancelButton: {
    backgroundColor: '#000000',
    color: '#fff',
    border: 'none',
    padding: '10px 15px',
    borderRadius: '5px',
    cursor: 'pointer',
    marginRight: '10px',
  },
  editButton: {
    backgroundColor: '#0275d8', // Azul
    color: '#fff',
    border: 'none',
    padding: '10px 15px',
    borderRadius: '5px',
    cursor: 'pointer',
    marginRight: '10px',
  },
  closeButton: {
    backgroundColor: '#d9534f',
    color: '#fff',
    border: 'none',
    padding: '10px 15px',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  closeEditModalButton: {
    backgroundColor: '#5bc0de', // Azul claro
    color: '#fff',
    border: 'none',
    padding: '10px 15px',
    borderRadius: '5px',
    cursor: 'pointer',
    marginTop: '20px',
  },
  errorText: {
    color: 'red',
    marginTop: '10px',
  },
};

export default EntregasList;
