import React, { useState } from 'react';
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth"; 
import Modal from 'react-modal'; 
import { Circles } from 'react-loader-spinner'; // Importar o componente de carregamento
import './signup.css'; 

Modal.setAppElement('#root'); 

const Signup = () => {
  const [formData, setFormData] = useState({
    establishmentName: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    phone: '',
    street: '',
    number: '',
    neighborhood: '',
    city: '',
    cpf: '',
  });

  const [modalIsOpen, setModalIsOpen] = useState(false); // Estado para controle do modal
  const [loading, setLoading] = useState(false); // Estado de carregamento

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Ativar o carregamento
    const auth = getAuth();
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, formData.email, formData.password);
      const user = userCredential.user;
      await sendDataToApi({ ...formData, userId: user.uid });
      console.log("Cadastro realizado com sucesso!", user);
      setModalIsOpen(true); // Abre o modal de sucesso
    } catch (error) {
      console.error("Erro ao cadastrar:", error);
    } finally {
      setLoading(false); // Desativar o carregamento após a operação
    }
  };

  const sendDataToApi = async (data) => {
    const response = await fetch('https://api.rapidao.app.br/clientes', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    const result = await response.json();
    console.log("Dados enviados para a API:", result);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    window.location.href = "/"; // Redireciona para a tela de login
  };

  return (
    <div className="signup-container">
      <div className="signup-card">
        <h2>Cadastrar Estabelecimento</h2>
        <form onSubmit={handleSubmit} className="signup-form">
          <div className="form-group">
            <input
              type="text"
              name="establishmentName"
              placeholder="Nome do Estabelecimento"
              value={formData.establishmentName}
              onChange={handleChange}
              className="input-field"
              required
            />
            <input
              type="text"
              name="firstName"
              placeholder="Nome"
              value={formData.firstName}
              onChange={handleChange}
              className="input-field"
              required
            />
            <input
              type="text"
              name="lastName"
              placeholder="Sobrenome"
              value={formData.lastName}
              onChange={handleChange}
              className="input-field"
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              className="input-field"
              required
            />
            <input
              type="text"
              name="phone"
              placeholder="Telefone"
              value={formData.phone}
              onChange={handleChange}
              className="input-field"
              required
            />
            <input
              type="text"
              name="cpf"
              placeholder="CPF"
              value={formData.cpf}
              onChange={handleChange}
              className="input-field"
              required
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              name="street"
              placeholder="Rua"
              value={formData.street}
              onChange={handleChange}
              className="input-field"
              required
            />
            <input
              type="text"
              name="number"
              placeholder="Número"
              value={formData.number}
              onChange={handleChange}
              className="input-field"
              required
            />
            <input
              type="text"
              name="neighborhood"
              placeholder="Bairro"
              value={formData.neighborhood}
              onChange={handleChange}
              className="input-field"
              required
            />
            <input
              type="text"
              name="city"
              placeholder="Cidade"
              value={formData.city}
              onChange={handleChange}
              className="input-field"
              required
            />
            <input
              type="password"
              name="password"
              placeholder="Senha"
              value={formData.password}
              onChange={handleChange}
              className="input-field"
              required
            />
          </div>
          <button type="submit" className="signup-button" disabled={loading}>
            {loading ? <Circles color="#FFFFFF" height={30} width={30} /> : 'Cadastrar'}
          </button>
        </form>
        <p>
          Já tem uma conta? <a href="/" className="login-link">Faça login</a>
        </p>
      </div>

      {/* Modal de sucesso */}
      <Modal 
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            transform: 'translate(-50%, -50%)',
            background: '#fff',
            padding: '20px',
            borderRadius: '10px',
          },
        }}
      >
        <h2>Cadastro Realizado com Sucesso!</h2>
        <p>Você foi cadastrado com sucesso. Agora você pode fazer login.</p>
        <button onClick={closeModal} className="signup-button">Ok</button>
      </Modal>
    </div>
  );
};

export default Signup;
