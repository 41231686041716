import React, { useEffect, useState } from 'react';
import { getDatabase, ref, onValue } from "firebase/database"; 
import { getAuth } from "firebase/auth";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal'; // Importar o Modal
import './topbar.css';
import AddBalance from '../AddBalance/AddBalance';

Modal.setAppElement('#root'); // Define o elemento raiz para acessibilidade

const TopBar = () => {
  const [userName, setUserName] = useState('');
  const [userBalance, setUserBalance] = useState(0);
  const [userAddress, setUserAddress] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false); // Estado do modal
  const auth = getAuth();
  const userId = auth.currentUser?.uid;

  useEffect(() => {
    const db = getDatabase();

    const fetchUserData = () => {
      if (userId) {
        const userRef = ref(db, `Empresas/${userId}`);
        onValue(userRef, (snapshot) => {
          if (snapshot.exists()) {
            const userData = snapshot.val();
            setUserName(userData.Nome.replace(/['"]+/g, ''));
            setUserBalance(userData.Saldo || 0);
            setUserAddress(userData.Endereço.replace(/['"]+/g, '') || '');
          } else {
            console.log("No data available");
          }
        });
      }
    };

    fetchUserData();
  }, [userId]);

  const handleLogout = () => {
    auth.signOut();
  };

  // Função para abrir o modal
  const openModal = () => {
    setModalIsOpen(true);
  };

  // Função para fechar o modal
  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className="topbar">
      <img src={require('../../assets/logo.png')} alt="Logo" className="logo" />
      <div className="user-info-container">
        <div className="saldo-card" onClick={openModal}> {/* Adiciona onClick para abrir o modal */}
          <strong>Adicionar saldo</strong>
        </div>
        <div className="saldo-card">
          <strong>{userBalance.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</strong>
        </div>
        <div className="user-info">
          <span className="user-name">Bem-vindo, {userName}</span>
          <div className="address">
            <FontAwesomeIcon icon={faMapMarkerAlt} className="marker-icon" />
            <span>{userAddress}</span>
          </div>
        </div>
        <div className="dropdown">
          <button 
            className="dropdown-button" 
            onClick={() => setDropdownOpen(!dropdownOpen)}
          >
            <FontAwesomeIcon icon={faSignOutAlt} />
          </button>
          {dropdownOpen && (
            <div className="dropdown-content">
              <button className="logout-button" onClick={handleLogout}>Sair</button>
            </div>
          )}
        </div>
      </div>

      {/* Modal */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Modal"
        className="modal" // Adicione suas classes CSS personalizadas aqui
        overlayClassName="modal-overlay" // Adicione suas classes CSS personalizadas para o overlay aqui
      >
        <AddBalance/>
      </Modal>
    </div>
  );
};

export default TopBar;
