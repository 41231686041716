// AddressComponent.js
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { getAuth } from "firebase/auth";
import { getDatabase, ref, get } from "firebase/database";
import { toast } from 'react-toastify';
import PropTypes from 'prop-types'; // Importar PropTypes
import './editAddressComponent.css';

// Estilos personalizados para o modal
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    padding: '20px',
    borderRadius: '8px',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  }
};

// Estilos para os cards da lista
const cardStyle = {
  border: '1px solid #ccc',
  borderRadius: '8px',
  padding: '15px',
  marginBottom: '10px',
  boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
};

const AddressComponent = ({ id_corrida }) => { // Receber id_corrida como prop
  const [address, setAddress] = useState('');
  const [addressDetails, setAddressDetails] = useState(null);
  const [userAddress, setUserAddress] = useState(null);
  const [minimo, setMinimo] = useState('');
  const [multiplicador, setMultiplicador] = useState('');
  const [telefone, setTelefone] = useState('');
  const [empresa, setEmpresa] = useState('');
  const [zona, setZona] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [distance, setDistance] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [confirmedAddresses, setConfirmedAddresses] = useState([]);
  const [numero, setNumero] = useState('');
  const [bairro, setBairro] = useState('');
  const [deliveryValue, setDeliveryValue] = useState(null);
  const [loading, setLoading] = useState(false);

  const auth = getAuth();

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      const uid = user.uid;
      fetchUserData(uid);
      if (id_corrida) {
        fetchExistingDelivery(uid, id_corrida); // Buscar dados da corrida existente
      }
    } else {
      toast.error('Usuário não autenticado.');
    }
  }, [auth, id_corrida]);

  const fetchUserData = async (uid) => {
    try {
      const db = getDatabase();
      const userRef = ref(db, `Empresas/${uid}`);
      const snapshot = await get(userRef);

      if (snapshot.exists()) {
        const userData = snapshot.val();
        setUserAddress(userData.Endereço || '');
        setMinimo(userData.Minimo || 5);
        setMultiplicador(userData.Multiplicador || 1.5);
        setTelefone(userData.Telefone || '');
        setEmpresa(userData.Nome || '');
        setZona(userData.Raio || '');
      } else {
        toast.error('Dados do usuário não encontrados.');
      }
    } catch (err) {
      toast.error('Erro ao buscar os dados do usuário.');
    }
  };

  const fetchExistingDelivery = async (uid, id_corrida) => {
    try {
      const db = getDatabase();
      const entregaRef = ref(db, `dtmovimento/${uid}/${id_corrida}`);
      const snapshot = await get(entregaRef);

      if (snapshot.exists()) {
        const entregaData = snapshot.val();
        const destinosExistentes = JSON.parse(entregaData.Destino);
        const obsExistentes = JSON.parse(entregaData.Obs);

        // **Remover ou comentar a parte que adiciona endereços existentes**
        // const existingAddresses = destinosExistentes.map((dest, index) => ({
        //   id: index, // ou um identificador único
        //   rua: dest.split('.')[0],
        //   numero: dest.split('.')[1]?.split('-')[0].trim() || '',
        //   bairro: dest.split('-')[1]?.trim() || '',
        //   distancia: '', // Opcional: calcular ou armazenar se disponível
        //   valorEntrega: obsExistentes[index][1],
        //   metodoPagamento: obsExistentes[index][3]
        // }));

        // **Não adicionar endereços existentes ao confirmedAddresses**
        // setConfirmedAddresses(existingAddresses);

        // **Se precisar exibir endereços existentes em outra parte do componente, armazene-os separadamente**
        // Por exemplo:
        // setExistingAddresses(existingAddresses);
      } else {
        toast.error('Corrida não encontrada.');
      }
    } catch (err) {
      console.error(err);
      toast.error('Erro ao buscar a corrida existente.');
    }
  };

  const fetchAddressDetails = async () => {
    if (!address.trim()) {
      toast.error('Por favor, insira um endereço válido.');
      return;
    }

    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)} Mossoró&key=AIzaSyCmwLaqycdIMerMWCUVJxN1_ezvMh96qxU`
      );
      const data = await response.json();

      if (data.status === 'OK') {
        const result = data.results[0];
        const { geometry } = result;
        const latLng = geometry.location; // Latitude e longitude do endereço pesquisado

        setAddressDetails({
          ...result,
          latLng
        });

        // Inicializar número e bairro com os valores obtidos
        const components = result.address_components;
        const rua = getAddressComponent(components, 'route');
        const numeroInicial = getAddressComponent(components, 'street_number');
        const bairroInicial = getAddressComponent(components, 'sublocality') || getAddressComponent(components, 'neighborhood');

        setNumero(numeroInicial || '');
        setBairro(bairroInicial || '');

        // Se o endereço do usuário foi encontrado, calcular a distância
        if (userAddress) {
          calculateDistance(latLng, userAddress);
        }

        setIsModalOpen(true);
      } else {
        toast.error('Endereço não encontrado.');
      }
    } catch (err) {
      console.error(err);
      toast.error('Erro ao buscar o endereço.');
    }
  };

  const getAddressComponent = (components, type) => {
    const component = components.find(comp => comp.types.includes(type));
    return component ? component.long_name : '';
  };

  const haversineDistance = (lat1, lon1, lat2, lon2) => {
    const toRad = (value) => (value * Math.PI) / 180; // Converter graus para radianos
    const R = 6371; // Raio da Terra em quilômetros
    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);
    
    const a = 
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * 
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distância em quilômetros
  };

  const calculateDistance = async (destination, originAddress) => {
    try {
      // Obter a latitude e longitude do endereço do usuário
      const userLatLng = await fetchUserLatLng(originAddress);
      const distanceValue = haversineDistance(userLatLng.lat, userLatLng.lng, destination.lat, destination.lng);
      setDistance(distanceValue.toFixed(2)); // Distância em quilômetros

      // Calcular o valor da entrega
      const delivery = minimo + (multiplicador * distanceValue);
      setDeliveryValue(delivery.toFixed(2));
    } catch (err) {
      console.error(err);
      toast.error('Erro ao calcular a distância.');
    }
  };

  const fetchUserLatLng = async (userAddress) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(userAddress)} Mossoró&key=AIzaSyCmwLaqycdIMerMWCUVJxN1_ezvMh96qxU`
      );
      const data = await response.json();

      if (data.status === 'OK') {
        return data.results[0].geometry.location; // Retorna as coordenadas do endereço do usuário
      } else {
        throw new Error('Erro ao obter coordenadas do endereço do usuário.');
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const handleConfirmAddress = () => {
    if (!paymentMethod) {
      toast.error('Por favor, selecione um método de pagamento.');
      return;
    }

    if (!numero.trim()) {
      toast.error('Por favor, insira o número do endereço.');
      return;
    }

    if (!bairro.trim()) {
      toast.error('Por favor, insira o bairro.');
      return;
    }

    if (!distance || !deliveryValue) {
      toast.error('Erro ao calcular a distância ou o valor da entrega.');
      return;
    }

    const delivery = parseFloat(deliveryValue);

    // Verificar se o endereço já está na lista de confirmedAddresses
    const isDuplicate = confirmedAddresses.some(addr => 
      addr.rua === getAddressComponent(addressDetails.address_components, 'route') &&
      addr.numero === numero.trim() &&
      addr.bairro === bairro.trim()
    );

    if (isDuplicate) {
      toast.error('Este endereço já foi adicionado.');
      return;
    }

    const newAddress = {
      id: Date.now(), // ID único
      rua: getAddressComponent(addressDetails.address_components, 'route') || 'N/A',
      numero: numero.trim(),
      bairro: bairro.trim(),
      distancia: `${distance} km`,
      valorEntrega: `R$ ${delivery.toFixed(2)}`,
      metodoPagamento: paymentMethod
    };

    setConfirmedAddresses([...confirmedAddresses, newAddress]);
    setIsModalOpen(false);
    setAddress('');
    setPaymentMethod('');
    setAddressDetails(null);
    setDistance(null);
    setDeliveryValue(null);
    setNumero('');
    setBairro('');
  };

  const handleRemoveAddress = (id) => {
    const updatedAddresses = confirmedAddresses.filter(address => address.id !== id);
    setConfirmedAddresses(updatedAddresses);
  };

  const handleSolicitarEntregador = async () => {
    const user = auth.currentUser;

    if (!user) {
      toast.error('Usuário não autenticado.');
      return;
    }

    const uid = user.uid;

    // Verificar se todos os dados necessários estão disponíveis
    if (!telefone || !empresa || !userAddress || !zona) {
      toast.error('Dados do usuário incompletos.');
      return;
    }

    if (confirmedAddresses.length === 0) {
      toast.error('Nenhum endereço confirmado para enviar.');
      return;
    }

    // Preparar a lista de destinos
    const destinos = confirmedAddresses.map(addr => ({
      rua: addr.rua,
      numero: addr.numero,
      bairro: addr.bairro,
      distancia: addr.distancia,
      metodoPagamento: addr.metodoPagamento,
      valorEntrega: parseFloat(addr.valorEntrega.replace('R$ ', '').replace(',', '.'))
    }));

    // Calcular o valor total
    const valorTotal = destinos.reduce((acc, dest) => acc + dest.valorEntrega, 0);

    // Preparar os dados para envio
    const data = {
      uid: uid,
      id_entrega: id_corrida, // Incluir id_corrida na solicitação
      destinos: destinos,
      valor: valorTotal.toFixed(2)
    };

    setLoading(true);
  
    try {
      const response = await fetch('https://api.rapidao.app.br/edit-entregas', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });

      if (response.ok) {
        toast.success('Corrida atualizada com sucesso.');
        // Opcional: Atualizar saldo ou outras informações conforme necessário
        // Limpar os endereços confirmados após a atualização, se desejado
        setConfirmedAddresses([]);
      } else {
        const errorData = await response.json();
        toast.error(`Erro na atualização: ${errorData.message || 'Erro desconhecido.'}`);
      }
    } catch (error) {
      console.error(error);
      toast.error('Erro ao atualizar a corrida.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ maxWidth: '600px', margin: '0 auto', padding: '20px' }}>

      {/* Campo de busca e botão de pesquisa */}
      <div style={{ display: 'flex', marginBottom: '20px' }}>
        <input
          type="text"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          placeholder="Buscar endereço"
          className="location-search-input"
        />
        <button onClick={fetchAddressDetails} className="search-button">
          Pesquisar
        </button>
      </div>

      {/* Modal para confirmar o endereço, selecionar método de pagamento e exibir a distância */}
      {addressDetails && (
        <Modal
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          style={customStyles}
          ariaHideApp={false}
        >
          <h2>Confirmar Endereço</h2>
        
          <div style={{ marginBottom: '10px' }}>
            <label><strong>Rua:</strong></label>
            <p>{getAddressComponent(addressDetails.address_components, 'route') || 'N/A'}</p>
          </div>
        
          <div style={{ marginBottom: '10px' }}>
            <label htmlFor="numero"><strong>Número:</strong></label>
            <input
              type="text"
              id="numero"
              value={numero}
              onChange={(e) => setNumero(e.target.value)}
              style={{ width: '100%', padding: '5px', borderRadius: '4px', border: '1px solid #ccc' }}
            />
          </div>
        
          <div style={{ marginBottom: '10px' }}>
            <label htmlFor="bairro"><strong>Bairro:</strong></label>
            <input
              type="text"
              id="bairro"
              value={bairro}
              onChange={(e) => setBairro(e.target.value)}
              style={{ width: '100%', padding: '5px', borderRadius: '4px', border: '1px solid #ccc' }}
            />
          </div>
        
          {/* Seletor de método de pagamento */}
          <div style={{ marginBottom: '10px' }}>
            <label htmlFor="payment-method"><strong>Método de Pagamento:</strong></label>
            <select
              id="payment-method"
              value={paymentMethod}
              onChange={(e) => setPaymentMethod(e.target.value)}
              style={{ marginTop: '5px', padding: '5px', width: '100%', borderRadius: '4px' }}
            >
              <option value="">Selecione...</option>
              <option value="Pago">Pago</option>
              <option value="Cartão">Cartão</option>
              <option value="Dinheiro">Dinheiro</option>
            </select>
          </div>
        
          {/* Exibição da distância calculada */}
          {distance && (
            <p><strong>Distância:</strong> {distance} km</p>
          )}
        
          {/* Exibição do valor da entrega */}
          {deliveryValue && (
            <div className="delivery-value-card">
              <p>R$ {deliveryValue}</p>
            </div>
          )}
        
          <div className="modal-buttons">
            <button className="button-confirm" onClick={handleConfirmAddress}>
              Confirmar
            </button>
            <button className="button-cancel" onClick={() => setIsModalOpen(false)}>
              Cancelar
            </button>
          </div>
        </Modal>
      )}

      {/* Lista de endereços confirmados */}
      {confirmedAddresses.length > 0 && (
        <div style={{ marginTop: '30px' }}>
          <h3>Endereços na corrida:</h3>
          {confirmedAddresses.map((addr) => (
            <div key={addr.id} className="address-card">
              <div className="address-details">
                {/* Endereço completo */}
                <p className="addr-rua">{addr.rua}, {addr.numero}, {addr.bairro}</p>
                
                {/* Card de detalhes com Distância, Pagamento e Valor */}
                <div className="details-row">
                  <div className="details-card">
                    <p>{addr.distancia}</p>
                  </div>
                  <div className="details-card">
                    <p>{addr.metodoPagamento}</p>
                  </div>
                  <div className="details-card">
                    <p>{addr.valorEntrega}</p>
                  </div>
                </div>
              </div>
              
              {/* Botão de remover à direita */}
              <button 
                onClick={() => handleRemoveAddress(addr.id)} 
                className="remove-button"
              >
                Remover
              </button>
            </div>
          ))}
          <button
            className="solicitar-entregador-button"
            style={{ 
              marginTop: '20px', 
              width: '100%', 
              padding: '15px', 
              backgroundColor: '#23ce5f', 
              color: 'white', 
              border: 'none', 
              borderRadius: '8px', 
              fontSize: '16px',
              cursor: 'pointer',
              opacity: loading ? 0.7 : 1,
              pointerEvents: loading ? 'none' : 'auto'
            }}
            onClick={handleSolicitarEntregador}
            disabled={loading}
          >
            {loading ? 'Atualizando...' : 'Atualizar Corrida'}
          </button>
        </div>
      )}
    </div>
  );
};

// Definir PropTypes para melhor manutenção e clareza
AddressComponent.propTypes = {
  id_corrida: PropTypes.string.isRequired
};

export default AddressComponent;
