import React, { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import TopBar from '../components/topbar/topbar';
import EntregasList from '../components/EntregasList/EntregasList';
import AddressComponent from '../components/AddressComponent/AddressComponent';
import AddBalance from '../components/AddBalance/AddBalance';
import './main.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';

// Configuração do root para acessibilidade
Modal.setAppElement('#root');

const Main = () => {
  const auth = getAuth();
  const navigate = useNavigate();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate('/');
      }
    });

    return () => unsubscribe();
  }, [auth, navigate]);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className="main-container">
      <TopBar user={auth.currentUser} />

      <div className="cards-container">
      <div className="card">
          <AddressComponent />
        </div>
        <div className="card">
          <EntregasList />
        </div>
      </div>


      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Address Component Modal"
        className="modal"
        overlayClassName="overlay"
      >
        <button className="close-modal-button" onClick={closeModal}>
          &times;
        </button>
        <AddressComponent />
      </Modal>

      <ToastContainer 
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
};

export default Main;
