import React, { useState, useEffect } from 'react';
import { auth } from '../firebaseConfig';
import { signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from 'react-router-dom'; // Importa useNavigate
import './Login.css'; 

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate(); // Cria uma instância do navigate

  // Verifica o estado de autenticação do usuário
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log("Usuário autenticado:", user);
        navigate('/inicio'); // Redireciona para a página inicial se já estiver autenticado
      } else {
        console.log("Usuário não autenticado");
      }
    });

    return () => unsubscribe(); // Limpa o listener quando o componente desmontar
  }, [navigate]);

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      console.log("Login successful");
      navigate('/inicio'); // Redireciona para a página inicial após o login
    } catch (error) {
      console.error("Login Failed", error);
    }
  };

  const handleRegister = () => {
    navigate('/signup'); // Redireciona para a tela de cadastro
  };

  return (
    <form onSubmit={handleLogin}>
      <div className="login-container">
        <div className="login-card">
          <h2>Entrar</h2>
          <input
            className="input-field"
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            placeholder="Email"
            required
          />
          <input
            className="input-field"
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            placeholder="Senha"
            required
          />
          <button className="login-button" type="submit">Login</button>
          <button type="button" className="register-button" onClick={handleRegister}>Cadastrar-se</button>
        </div>
      </div>
    </form>
  );
}

export default Login;
