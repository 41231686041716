import React, { useEffect, useState } from 'react';
import { getAuth } from "firebase/auth";
import { getDatabase, ref, onValue } from "firebase/database";
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { QRCodeCanvas } from 'qrcode.react'; // Importe o QRCodeCanvas
import './AddBalance.css'; // Opcional: Crie um arquivo CSS para estilizar o componente

const AddBalance = () => {
  const [amount, setAmount] = useState('');
  const [qrCodeText, setQrCodeText] = useState(null);
  const [paymentId, setPaymentId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(null); // Para armazenar o status do pagamento

  const auth = getAuth();
  const user = auth.currentUser;
  const userId = user?.uid;

  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const db = getDatabase();

    const fetchUserData = () => {
      if (userId) {
        const userRef = ref(db, `Empresas/${userId}`);
        onValue(userRef, (snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val();
            setUserData(data);
          } else {
            console.log("No data available for user");
          }
        });
      }
    };

    fetchUserData();
  }, [userId]);

  // Verifica o status do pagamento a cada 5 segundos
  useEffect(() => {
    const checkPaymentStatus = async () => {
      if (!paymentId) return;

      try {
        const response = await axios.post('https://api.rapidao.app.br/api/pix/check-pix-payment', {
          uid: userId,
          id: paymentId,
        });
        const { status } = response.data; // Ajuste conforme o formato da resposta

        setPaymentStatus(status);

        // Se o pagamento for confirmado, você pode adicionar saldo ou realizar outra ação
        if (status === 'approved') {
          alert('Pagamento aprovado! Seu saldo foi atualizado.');
          // Aqui você pode adicionar a lógica para atualizar o saldo do usuário no Firebase
          // e, em seguida, limpar o estado se necessário
        }
      } catch (err) {
        console.error('Erro ao verificar o status do pagamento:', err.response ? err.response.data : err.message);
      }
    };

    const intervalId = setInterval(checkPaymentStatus, 5000); // Verifica a cada 5 segundos

    return () => clearInterval(intervalId); // Limpa o intervalo ao desmontar o componente
  }, [paymentId, userId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setQrCodeText(null);
    setPaymentId(null);
    setPaymentStatus(null); // Resetar status do pagamento

    // Validação básica
    if (!amount || isNaN(amount) || parseFloat(amount) <= 0) {
      setError('Por favor, insira um valor válido.');
      return;
    }

    if (!userId) {
      setError('Usuário não autenticado.');
      return;
    }

    setLoading(true);

    try {
      const externalReference = `Ref_${uuidv4()}`;
      const description = 'Saldo';

      const requestData = {
        uid: userId,
        transaction_amount: parseFloat(amount),
        external_reference: externalReference,
        description: description
      };

      const response = await axios.post('https://api.rapidao.app.br/api/pix/create-pix-payment', requestData);
      console.log(response.data);
      const { qr_code, payment_id } = response.data;
      setQrCodeText(qr_code);
      setPaymentId(payment_id);
    } catch (err) {
      console.error('Erro ao criar pagamento Pix:', err.response ? err.response.data : err.message);
      setError(err.response?.data?.error || 'Erro ao criar pagamento Pix.');
    } finally {
      setLoading(false);
    }
  };

  const copyToClipboard = () => {
    if (qrCodeText) {
      navigator.clipboard.writeText(qrCodeText)
        .then(() => {
          alert('Texto copiado para a área de transferência!');
        })
        .catch((err) => {
          console.error('Erro ao copiar o texto: ', err);
        });
    }
  };

  return (
    <div className="add-balance-container">
      <h2>Adicionar Saldo via Pix</h2>
      
      {!qrCodeText ? (
        <form onSubmit={handleSubmit} className="add-balance-form">
          <label>
            Valor:
            <input
              type="number"
              step="0.01"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              placeholder="Insira o valor"
              required
              className="styled-input"
            />
          </label>
          <button type="submit" disabled={loading}>
            {loading ? 'Processando...' : 'Gerar QR Code'}
          </button>
        </form>
      ) : (
        <div className="qr-code-container" style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <h3>Escaneie o QR Code para pagar:</h3>
          <QRCodeCanvas value={qrCodeText} size={200} />
          <button 
            onClick={copyToClipboard} 
            className="copy-button" 
            style={{ 
              backgroundColor: 'blue', 
              color: 'white', 
              border: 'none', 
              padding: '10px 20px', 
              borderRadius: '5px', 
              cursor: 'pointer', 
              marginTop: '10px' 
            }}
          >
            Copiar Pix
          </button>
          {paymentStatus && <p>Status do pagamento: {paymentStatus}</p>} {/* Mostra o status do pagamento */}
        </div>
      )}

      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

export default AddBalance;
